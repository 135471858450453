import React from 'react';
import { connect } from 'react-redux';
import RedirectSignIn from '../RedirectSignIn';

const AppSessionLoading = (props) => {
  return (<span>Carregando dados de sessão...</span>);
}

const AppSessionGateway = (props) => {
  if (!props.touched) {
    return (<AppSessionLoading />);
  }

  if (props.fetching) {
    return (<AppSessionLoading />);
  }

  if (props.authenticated) {
    return props.children;
  }

  return (<RedirectSignIn />);
}

export default connect((state) => state.auth)(AppSessionGateway);