import React, { useState } from 'react';
import styled from 'styled-components';
import { Hub } from 'aws-amplify';

// Components
import { AppBar } from '@teewe/components';
import { FullContainer } from '@teewe/components';
import { IconButton } from '@teewe/components';
import { DropdownMenu } from '@teewe/components';
import { DropdownMenuItem } from '@teewe/components';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import AppBarAvatar from '../AppBarAvatar';
import AppBarSearch from '../../components/AppBarSearch';
import LayoutSidebar from '../../components/LayoutSidebar';

const StyledPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  page: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#ebebf8',
    color: '#5b5b5b',
    display: 'inline',
    textAlign: 'left',
    height: '100vh',
    overflowY: 'scroll'
  },
  appBarRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box'
  },
  appBarProject: {
    display: 'flex',
    flexDirecion: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '12px'
  },
  appBarSearch: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    marginLeft: '40px'
  },
  appBarSearchInput: {
    width: '100%',
    display: 'inline',
    float: 'left'
  },
  appBarControls: {
    marginLeft: '240px'
  },
  appBarProjectLogo: {
    marginRight: '20px'
  },
  inputIcon: {
    margin: '0px 7px',
    marginTop: '5px'
  },
  pageHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 0px',
    borderBottom: '1px solid rgba(0,0,0,0.15)'
  },
  pageHeaderIcon: {
    margin: '0px 7px'
  }
};

function Layout ({ children, ...props }) {
  const [ open, setOpen ] = useState(true);
  const [ showMenu, setShowMenu ] = useState(false);

  const handleToggleSidebar = () => {
    setOpen(!open);
  }

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const handleSignOut = () => {
    Hub.dispatch('auth.sign_out', {});
  }

  return (
    <div className="App">
      <LayoutSidebar open={open} />
      <StyledPageContainer>
        <AppBar>
          <FullContainer style={styles.appBar}>
            <IconButton color="primary" onClick={handleToggleSidebar}>
              <MenuIcon />
            </IconButton>
            <div style={styles.appBarRoot}>
              <AppBarSearch />
              <div style={styles.appBarControls}>
                <AppBarAvatar onClick={handleToggleMenu} />
                <DropdownMenu open={showMenu} style={{marginLeft: '-150px', width: '220px', height: 'auto', minHeight: '0px', padding: '0px', boxShadow: '0px 0px 5px rgba(0,0,0,0.075), 0px 5px 5px rgba(0,0,0,0.15)', borderRadius: '23px'}}>
                  <DropdownMenuItem as="button" onClick={handleSignOut}>Sair</DropdownMenuItem>
                </DropdownMenu>
              </div>
            </div>
          </FullContainer>
        </AppBar>
        <div style={styles.page}>
          <FullContainer>
            { children }
          </FullContainer>
        </div>
      </StyledPageContainer>
    </div>
  );
}

export default Layout;
