import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Hub } from 'aws-amplify';
//import { SnackbarProvider } from 'notistack';

// Shared instances
import './shared/amplify';
import store from './store';
import uploadManager from './shared/uploads';
import auth from './shared/auth';
import httpClient from './shared/http-client';

import Routes from './Routes';
import './App.scss';
import './listeners';

import rememberJs from './library/remember';

import AppSessionGateway from './components/AppSessionGateway';
import AppLoaderGateway from './components/AppLoaderGateway';

if (process.env.NODE_ENV === 'development') {
  window.uploadManager = uploadManager;
  //window.store = store;
  window.auth = auth;
  window.httpClient = httpClient;
  window.remember = rememberJs;
  window.Hub = Hub;
}

Hub.listen('user', (e) => {
  if (e.payload.event === 'token_ok') {
    auth.token = e.payload.data.accessToken;
  }
});

console.log('environment', process.env.NODE_ENV);

/*
store.dispatch({
  type: 'PROJECT_SAGA',
  payload: {
    type: 'FETCH',
    id: 'ffadc1b3-de2f-47b0-9e62-ee5df438562f'
  }
});
*/

/*
uploadManager.items.push({
  albumId: "d42f7dd4-4d6f-4ad3-af60-5796a27440fa",
  albumName: "AAAA",
  collectionId: "d42f7dd4-4d6f-4ad3-af60-5796a27440fa",
  files: [],
  id: "b07a4490-fbc2-49da-a9c3-34961dd4ca3f",
  index: 0,
  makeChildren: true,
  projectId: "1085c177-d4ef-4da7-b0c2-66130b416588",
  status: "COMPLETED",
  uploadStatus: "PENDING",
  analyseStatus: "PENDING"
});

uploadManager.nextValue();
*/

uploadManager.subscribe((nextVal) => {
  store.dispatch({
    type: 'UPLOADS.UPDATE_STACKS',
    payload: {
      stacks: nextVal.reverse().map((uploadGroup) => {
        var doneItems = uploadGroup.files.filter((file) => file.status === 'COMPLETED');

        return {
          id: uploadGroup.id,
          projectId: uploadGroup.projectId,
          key: uploadGroup.index,
          status: uploadGroup.status,
          albumId: uploadGroup.albumId,
          albumName: uploadGroup.albumName,
          makeChildren: uploadGroup.makeChildren,
          collectionId: uploadGroup.collectionId,
          totalCount: uploadGroup.files.length,
          doneCount: doneItems.length,
          uploadStatus: uploadGroup.uploadStatus,
          analyseStatus: uploadGroup.analyseStatus,
          progressBytes: uploadGroup.files.reduce((total, file) => (total + file.progress), 0),
          totalBytes: uploadGroup.files.reduce((total, file) => (total + file.size), 0)
        }
      })
    }
  });
});

function App() {
  useEffect(() => {
    //Hub.dispatch('initialize', {});
    store.dispatch({
      type: 'APP',
      payload: {
        type: 'REQUEST'
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <AppLoaderGateway>
        <AppSessionGateway>
          <Routes />
        </AppSessionGateway>
      </AppLoaderGateway>
    </Provider>
  );
}

export default App;
