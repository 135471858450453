import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@teewe/components';
import { Chip } from '@teewe/components';

const withCollections = connect((state) => {
  return {
    collections: state.collections
  }
});

const textSubstring = (text) => {
  if (text && text.length > 10) {
    return text.substring(0, 7) + '...';
  }

  return text;
}

export const AutocompleteChipAlbumItem = ({ chip, collections, itemLabel, dispatch, onDeselect, ...props }) => {
  useEffect(() => {
    if (!collections.memory[chip]) {
      dispatch({
        type: 'COLLECTIONS.FETCH',
        payload: {
          id: chip
        }
      });
    }
  // eslint-disable-next-line
  }, [ chip ]);

  const collection = collections.memory[chip];

  if (!collection || collection.fetching) {
    return (
      <Chip color="primary" size="small" title={'Carregando...'} {...props}>
        Carregando...
      </Chip>
    )
  }

  if (collection.error) {
    return (
      <Chip color="error" size="small" title={'Error'} {...props}>
        Error
      </Chip>
    );
  }

  return (
    <Chip color="primary" size="small" {...props}>
      <IconButton size="small" color="primary" onClick={onDeselect}>
        <i className="fas fa-times-circle"></i>
      </IconButton>
      { textSubstring(collection?.data?.title) }
    </Chip>
  )
}

export default withCollections(AutocompleteChipAlbumItem);