import React from 'react';

import { GridTable } from '@teewe/components';
import { GridRow } from '@teewe/components';
import { GridColumn } from '@teewe/components';

// Components
import MockAlbumItem from '../MockAlbumItem';

const MockAlbumList = (props) => {
  const albums = [1, 2, 3, 4, 5];

  return (
    <GridTable>
      <GridRow>
        {
          albums.map((item, itemIndex) => (
            <GridColumn key={itemIndex} style={{width: '16.66%', boxSizing: 'border-box'}}>
              <MockAlbumItem />
            </GridColumn>
          ))
        }
      </GridRow>
    </GridTable>
  )
}

export default MockAlbumList;