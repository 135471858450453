import { combineReducers } from 'redux';

// Reducers
import app from './reducers/app';
import ui from './reducers/ui';
import uploads from './reducers/uploads';
import auth from './reducers/auth';
import collections from './reducers/collections';
import projects from './reducers/projects';
import project from './reducers/project';

export default combineReducers({
  'app': app,
  'ui': ui,
  'auth': auth,
  'uploads': uploads,
  'collections': collections,
  'projects': projects,
  'project': project
});