import React, { useState } from 'react';
import { AppSidebarTitle } from '@teewe/components';
import { AppSidebarMenu } from '@teewe/components';
import { AppSidebarMenuItem } from '@teewe/components';
import { AppSidebarMenuItemContent } from '@teewe/components';
import { AppSidebarMenuItemIcon } from '@teewe/components';
import { Divider } from '@teewe/components';

import { Hub } from 'aws-amplify';

// Components
import CreateProjectDialog from '../CreateProjectDialog';

const initialProjectData = {
  name: '',
  open: true
};

const SidebarMenuProject = ({ index, menu, section, ...props }) => {
  const [ open, setOpen ] = useState(false);
  const [ projectData, setProjectData ] = useState(initialProjectData);

  const handleCreate = () => {
    setOpen(true);
  }

  const handleClose = (action) => {
    setOpen(false);

    if (action === 'confirm') {
      const createEventParams = {
        event: 'create',
        data: {
          name: projectData.name,
          open: projectData.open
        }
      };

      Hub.dispatch('project', createEventParams);
      setOpen(false);
      setProjectData(initialProjectData);
    }
  }
  
  return (
    <React.Fragment {...props}>
      <AppSidebarTitle>{section.title}</AppSidebarTitle>
      <AppSidebarMenu>
        <AppSidebarMenuItem onClick={handleCreate}>
          <AppSidebarMenuItemIcon>
            <i className="fa fa-plus"></i>
          </AppSidebarMenuItemIcon>
          <AppSidebarMenuItemContent>
            Novo projeto
          </AppSidebarMenuItemContent>
        </AppSidebarMenuItem>

        <CreateProjectDialog
          open={open}
          data={projectData}
          onChange={setProjectData}
          onClose={handleClose}
        />
      </AppSidebarMenu>
      { (menu.length > index + 1) && (<Divider />) }
    </React.Fragment>
  )
}

export default SidebarMenuProject;