
class QueueFifo {

  constructor () {
    this.stack = [];
    this.current = -1;
    this.last = -1;
  }

  push = (task) => {
    this.stack.push(task);
    this.last++;
  }

  next = () => {
    this.current++;

    if (this.current > this.last) {
      return undefined;
    }

    return this.stack[this.current];
  }

  size = () => {
    return this.last - this.current;
  }

  peek = () => {
    let index = this.current + 1;

    if (index > this.last) {
      return undefined;
    }

    return this.stack[index];
  }
}

export default QueueFifo;
