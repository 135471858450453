import { Hub } from 'aws-amplify';

import auth from '../shared/auth';
import httpClient from '../shared/http-client';
import manager from '../shared/uploads';

const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_API}/graphql/_`;

/**
 * Create an album
 * @param {Object} params 
 * @param {Object} params.projectId
 * @param {Object} params.title
 * @returns {Promise}
 */
const createAlbumAsync = async (params) => {
  try {
    const requestBody = {
      query: `
        mutation createAlbum ($input: CreateAlbumInput!) {
          createAlbum(input: $input) {
            error
            data {
              id
              projectId
              title
            }
          }
        }
      `,
      variables: {
        input: params
      }
    };
  
    const response = await httpClient.post(`${process.env.REACT_APP_API}/graphql/_`, requestBody);
    const { createAlbum } = response.data.data;
    return createAlbum;
  } catch (e) {
    return {
      error: e
    }
  }
}

/**
 * Fetch an album
 * @param {Object} params
 * @param {String} params.albumId
 */
const fetchAlbumAsync = async (params) => {
  try {
    const requestBody = {
      query: `
        query getAlbums ($input: GetAlbumsInput!) {
          getAlbums (input: $input) {
            error
            data {
              count
              edges {
                id
                title
                projectId
              }
            }
          }
        }
      `,
      variables: {
        input: {
          id: params.albumId
        }
      }
    };

    const response = await httpClient.post(GRAPHQL_ENDPOINT, requestBody);
    const { getAlbums } = response.data.data;
    return getAlbums;
  } catch (e) {
    return {
      error: e
    }
  }
}

/**
 * Fetch upload status
 * @param {Object} params
 * @param {String} params.id
 */
const fetchUploadStatusAsync = async (params) => {
  var requestBody = {
    query: `
      query getUpload($input: _GetUploadsInput!) {
        getUpload: getUploads(input: $input) {
          error
          data {
            count
            edges {
              id
              uploadStatus
              analyseStatus
              createdAt
              finishedAt
              exceptions {
                error
                data {
                  count
                  edges {
                    errorCode
                    abortReason
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        id: params.id,
        size: 1
      }
    }
  }

  var response = await httpClient.post(GRAPHQL_ENDPOINT, requestBody);
  const { getUpload } = response.data.data;

  if (getUpload.error) {
    return {
      error: getUpload.error
    }
  }

  if (getUpload.data.count < 1) {
    return {
      error: 'not_found'
    }
  }

  return {
    error: null,
    data: getUpload.data.edges[0]
  };
}

const uploadsCreateListener = async (data) => {
  const { channel, payload } = data;

  if (channel === 'uploads') {
    if (payload.event !== 'create') {
      return;
    }
  }

  var context = {
    projectId: payload.projectId,
    token: auth.token
  };

  var createUploadParams = {
    projectId: payload.projectId,
    albumId: payload.target.collectionId,
    albumName: null,
    collectionId: payload.target.collectionId, // TODO: Remove later
    makeChildren: Boolean(payload.makeChildren),
    files: payload.files
  };

  // Create album if not exists...
  if (payload.target.createRecord) {
    var createAlbumParams = {
      projectId: context.projectId,
      title: payload.target.collectionName
    };

    var createAlbumResponse = await createAlbumAsync(createAlbumParams);

    if (createAlbumResponse.error) {
      Hub.dispatch('uploads', {
        event: 'error',
        previousEvent: { ...payload },
        error: createAlbumResponse.error
      });

      return;
    }

    if (createAlbumResponse.data.id) {
      createUploadParams.albumId = createAlbumResponse.data.id;
      createUploadParams.albumName = createAlbumResponse.data.title;
      createUploadParams.collectionId = createAlbumResponse.data.id;
    }
  }

  if (!createUploadParams.albumName) {
    var fetchAlbumParams = {
      albumId: createUploadParams.albumId
    };

    var fetchAlbumResponse = await fetchAlbumAsync(fetchAlbumParams);

    if (fetchAlbumResponse.error) {
      Hub.dispatch('uploads', {
        event: 'error',
        previousEvent: { ...payload },
        error: fetchAlbumResponse.error
      });

      return;
    }

    if (fetchAlbumResponse.data.count < 1) {
      Hub.dispatch('uploads', {
        event: 'error',
        previousEvent: { ...payload },
        error: {
          message: 'album not found',
          details: {
            albumId: createUploadParams.albumId
          }
        }
      });

      return;
    }

    createUploadParams.albumName = fetchAlbumResponse.data.edges[0].title;
  }

  manager.addUpload(createUploadParams, context);
}

Hub.listen('upload.create', uploadsCreateListener);
//Hub.listen('uploads', uploadsCreateListener);

Hub.listen('uploads', ({ payload }) => {
  console.log('uploads', { payload });
});

Hub.listen('uploads', ({ payload }) => {
  if (payload.event !== 'upload_completed') {
    return;
  }

  Hub.dispatch('uploads', {
    type: 'polling_subscribe',
    id: payload.data.id
  });
});

var subscribers = [];

Hub.listen('uploads', ({ payload }) => {
  if (payload.type !== 'polling_subscribe') {
    return;
  }

  if (subscribers.indexOf(payload.id) === -1) {
    subscribers.push(payload.id);
  }
});

Hub.listen('uploads', ({ payload }) => {
  if (payload.type !== 'polling_unsubscribe') {
    return;
  }

  var index = subscribers.indexOf(payload.id);

  if (index !== -1) {
    subscribers.splice(index, 1);
  }
});

Hub.listen('uploads', ({ payload }) => {
  if (payload.type !== 'polling_run') {
    return;
  }

  subscribers.map((upload) => {
    return fetchUploadStatusAsync({ id: upload })
      .then((response) => {
        if (response.error) {
          return;
        }

        Hub.dispatch('uploads', {
          type: 'polling_response',
          id: upload,
          data: response.data
        });
      })
      .catch((err) => {
        console.error(err);
      })
  });
});

Hub.listen('uploads', ({ payload }) => {
  if (payload.type !== 'polling_response') {
    return;
  }

  const isCompleted = payload.data.uploadStatus === 'COMPLETED' && payload.data.analyseStatus === 'COMPLETED';
  const index = manager.findById(payload.id);

  if (index === -1) {
    return;
  }

  var item = manager.getItemAt(index);
  //item.uploadStatus = payload.data.uploadStatus;
  item.analyseStatus = payload.data.analyseStatus;
  manager.nextValue();

  if (isCompleted) {
    Hub.dispatch('uploads', {
      type: 'polling_unsubscribe',
      id: payload.id
    });
  }
});

setInterval(() => {
  if (subscribers.length > 0) {
    Hub.dispatch('uploads', { type: 'polling_run' });
  }
}, 5000);

