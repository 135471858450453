import { Hub, Auth } from 'aws-amplify';

Hub.listen('auth.sign_in', ({ payload }) => {
  console.log('sign in', payload);
});

Hub.listen('auth.sign_out', ({ payload }) => {
  console.log('sign out', payload);
  Auth.signOut();
  window.location.reload();
});