import React from 'react';
import { Skeleton } from '@teewe/components';
import { Divider } from '@teewe/components';

// Components
import Layout from '../../components/Layout';
import MockAlbumList from '../../components/MockAlbumList';

const LoadingPage = (props) => {
  return (
    <Layout>
      <Skeleton effect="fade" style={{marginTop: '20px' }} />
      <Skeleton effect="fade" style={{width: '50%'}} />
      <Divider style={{marginTop: '15px'}} />
      <Skeleton effect="fade" style={{width: '60%', marginTop: '20px', marginBottom: '20px' }} />
      <MockAlbumList />
    </Layout>
  )
}

export default LoadingPage;