import React from 'react';
import { connect } from 'react-redux';

import { Avatar } from '@teewe/components';
import MockAvatar from '../MockAvatar';

const AppBarAvatar = ({ onClick, auth, ...props }) => {
  const { touched, fetching, authenticated, user } = auth;

  if (!touched || fetching || !authenticated) {
    return (<MockAvatar />)
  }

  return (
    <Avatar
      name={user.email}
      onClick={onClick}
      style={{cursor: 'pointer'}}
    />
  )
}

AppBarAvatar.defaultProps = {
  onClick: () => {}
};

export default connect((state) => {
  return {
    auth: state.auth
  }
})(AppBarAvatar);