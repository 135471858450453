import React from 'react';
import { Skeleton } from '@teewe/components';

const MockAlbumItem = (props) => {
  return (
    <div>
      <Skeleton effect="fade" height={'200px'} />
      <Skeleton effect="fade" />
      <Skeleton effect="fade" />
    </div>
  )
}

export default MockAlbumItem;