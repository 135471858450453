import React from 'react';
import { AppSidebarMenuItem } from '@teewe/components';
import { AppSidebarMenuItemContent } from '@teewe/components';
import { AppSidebarMenuItemIcon } from '@teewe/components';
import { useHistory } from 'react-router';

const MenuItem = ({ item, ...props }) => {
  const history = useHistory();
  var active = false;

  if (history && history.location) {
    active = history.location.pathname === item.href;
  }

  const handleClick = () => {
    history.push(item.href);
  }

  return (
    <AppSidebarMenuItem {...props} active={active} onClick={handleClick}>
      <AppSidebarMenuItemIcon>
        <i className={`${item.icon}`}></i>
      </AppSidebarMenuItemIcon>
      <AppSidebarMenuItemContent>
        { item.title }
      </AppSidebarMenuItemContent>
    </AppSidebarMenuItem>
  )
}

export default MenuItem;