import collection from './collection';
import upload from './upload';
import photo from './photo';

export default {
  collection,
  upload,
  photo,
  contextualize: function (fn, params, context) {
    return fn(params, context);
  }
};