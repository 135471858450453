import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { ButtonGroup } from '@teewe/components';
import { Button } from '@teewe/components';
import { Typography } from '@teewe/components';
import { TextField } from '@teewe/components';

import Switch from '../Switch';
//import CreateProjectForm from '../CreateProjectForm';

const StyledDialogFooter = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const styles = {
  root: {
    minWidth: '400px'
  }
};

const CreateProjectDialog = ({ data, onClose, onChange, onConfirm, ...props }) => {
  const handleChange = useCallback((name, value) => {
    if (onChange) {
      var dataUpdated = {
        ...data,
        [name]: name === 'name' ? value.target.value : value
      };

      onChange(dataUpdated);
    } 
  }, [data, onChange]);

  const isValid = data && data.name && data.name.length > 0;

  return (
    <Dialog {...props} onClose={onClose.bind(this, 'cancel')}>
      <DialogTitle>Criar novo projeto</DialogTitle>
      <DialogContent>
        <div style={styles.root}>
          <TextField
            placeholder="Nome"
            value={data.name || ''}
            onChange={handleChange.bind(this, 'name')}
          />

          <div style={{display: 'flex', alignItems: 'center', marginTop: '12px'}}>
            <Switch
              checked={Boolean(data.open)}
              onToggle={handleChange.bind(this, 'open')}
              style={{marginRight: '8px'}}
            />
            <Typography as="label" variant="span">Abrir o projeto depois da criação?</Typography>
          </div>

          <StyledDialogFooter>
            <ButtonGroup>
              <Button variant="text" onClick={onClose.bind(this, 'cancel')}>Cancelar</Button>
              <Button variant="contained" color="primary" onClick={onClose.bind(this, 'confirm')} disabled={!isValid}>Criar projeto</Button>
            </ButtonGroup>
          </StyledDialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}

CreateProjectDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object
}

CreateProjectDialog.defaultProps = {
  open: false,
  data: { name: '', open: true },
  onClose: () => {},
  onChange: () => {},
  onConfirm: () => {},
}

export default CreateProjectDialog;