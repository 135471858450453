import { call, put, select } from 'redux-saga/effects';
import httpClient from '../../shared/http-client';
import { getItem } from '../../library/remember';

async function fetchProject (projectId) {
  try {
    const requestBody = {
      query: `
        query getProjects($input: GetProjectsInput!) {
          getProjects(input: $input) {
            error
            data {
              count
              edges {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        input: {
          id: projectId,
          from: 0,
          size: 1
        }
      }
    };

    const response = await httpClient.post(`${process.env.REACT_APP_API}/graphql/_`, requestBody);
    const { getProjects } = response.data.data;

    if (getProjects.error) {
      return {
        error: getProjects.error
      }
    }

    if (getProjects.data.count < 1) {
      return {
        error: 'not found'
      }
    }

    return {
      error: null,
      data: getProjects.data.edges[0]
    }
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

export default function* (action) {
  const store = yield select((state) => state.project);

  if (action.type !== 'PROJECT_SAGA') {
    return;
  }

  if (action.payload.type !== 'FETCH') {
    return;
  }

  var item = getItem(store, action.payload.id);

  if (!item) {
    item = {
      touched: false,
      fetching: false,
      error: null,
      data: null
    };
  }

  if (!item.touched || action.payload.force) {
    yield put({
      type: 'PROJECT',
      payload: {
        type: 'SET_ITEM',
        id: action.payload.id,
        data: {
          ...item,
          touched: true,
          fetching: true,
          error: null,
          data: null
        }
      }
    });

    var getProjectResponse = yield call(fetchProject, action.payload.id);
    var responseActionData = {
      touched: true,
      fetching: false,
      error: getProjectResponse.error,
      data: null
    };

    if (!getProjectResponse.error) {
      responseActionData.error = null;
      responseActionData.data = getProjectResponse.data;
    }

    yield put({
      type: 'PROJECT',
      payload: {
        type: 'SET_ITEM',
        id: action.payload.id,
        data: responseActionData
      }
    });
  }
}