import React from "react";
import styled from "styled-components";

const StyledDropdownListItem = styled.div`
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  color: #5b5b5b;
  padding: 7px;
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: rgba(0,0,0,0.075);
  }
`

const DropdownListItem = ({ children, ...props }) => {
  return (<StyledDropdownListItem {...props}>{children}</StyledDropdownListItem>)
}

export default DropdownListItem;