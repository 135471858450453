const DEFAULT_STATE = {
  count: 0,
  bytes: {
    progress: 0,
    total: 0
  },
  _pending: [],
  _poll: {},
  _raw: [
    {
      id: '12344',
      status: 'PENDING',
      makeChildren: true,
      collectionId: '1b8f724b-1fe5-4c85-8604-873d441b2cfd',
      totalCount: 126,
      doneCount: 17,
      progressBytes: 1234,
      totalBytes: 12345
    }
  ],
  uploads: [
    /* {
      _id: 'upload_manager.1', // Local ID
      requestId: '3e59746b-d26d-48fd-b5ba-16e0847b4d69', // Upload request ID
      albumId: '1b8f724b-1fe5-4c85-8604-873d441b2cfd', // Target album ID
      requestCreated: false,
      filesUploaded: false,
      uploadStarted: false,
      uploadCompleted: false,
      cancelled: false,
      files: [
        {
          resource: new File(), // File reference
          name: 'photo-0001.jpg',
          total: 14557,
          progress: 0,
          controls: new Controls() {
            onProgress: function...
            start: function...
            cancel: function...
          }
        },
        {
          resource: new File(),
          name: 'photo-0002.jpg',
          total: 60000,
          progress: 0
        },
        {
          resource: new File(),
          name: 'photo-0003.jpg',
          total: 80000,
          progress: 0
        }
      ]
    } */
  ]
};

const actions = {
  'UPLOADS.POLL_RESPONSE': (state, payload) => {
    // Code here
    return state;
  },
  'UPLOADS.UPDATE_STACKS': (state, payload) => {
    var newState = { ...state };
    newState._raw = payload.stacks;
    return newState;
  }
}

export default (state = DEFAULT_STATE, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action.payload);
  }

  return state;
}