import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

const middleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(middleware));
middleware.run(sagas);

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

export default store;