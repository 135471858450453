import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { getItem } from '../../library/remember';

import { Chip } from '@teewe/components';

// Components
import SelectProjectDialog from '../SelectProjectDialog';

const getProjectName = (projectId, selected) => {
  if (!projectId) {
    return 'SELECIONE UM PROJETO';
  }

  if (!selected) {
    return '...';
  }

  if (selected.fetching) {
    return 'CARREGANDO...';
  }

  return selected.data.name;
}

const withProjects = connect((state) => {
  return {
    projectId: state.projects.current,
    selected: getItem(state.project, state.projects.current)
  }
});

const AppBarProject = ({ projectId, selected, ...props }) => {
  const [ open, setOpen ] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'PROJECT_SAGA',
      payload: {
        type: 'FETCH',
        id: projectId
      }
    });
  }, [ dispatch, projectId ]);

  const handleClick = (e) => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleConfirm = (e) => {
    setOpen(false);
  }

  return (
    <>
      <Chip color="primary" onClick={handleClick}>
        <strong>
          {getProjectName(projectId, selected)}
        </strong>
        <small style={{marginLeft: '4px'}}>
          <i className={'fas fa-caret-down'}></i>
        </small>
      </Chip>
      <SelectProjectDialog open={open} onClose={handleClose} onConfirm={handleConfirm} />
    </>
  )
}

export default withProjects(React.memo(AppBarProject));