
/**
 * Create a store
 * @param {Object} params 
 * @param {Number} params.max
 * @returns {Object} store
 */
export function createStore (params) {
  const { max } = params;

  return {
    settings: {
      max: max || 100,
      sequenceNumber: 0 // Last sequence number
    },
    items: []
  }
}

/**
 * Find a item
 * @param {Object} store 
 * @param {String} key 
 * @returns {Number} index
 */
export function findIndex (store, key) {
  var index = -1;

  for (var i = 0; i < store.items.length; i++) {
    if (store.items[i].key === key) {
      index = i;
      break;
    }
  }

  return index;
}

/**
 * Check item exists
 * @param {Object} store 
 * @param {String} key 
 * @returns {Boolean}
 */
export function hasItem (store, key) {
  return findIndex(store, key) !== -1;
}

/**
 * Get item
 * @param {Object} store 
 * @param {String} key
 * @returns {mixed} item data
 */
export function getItem (store, key) {
  var index = findIndex(store, key);

  if (index === -1) {
    return null;
  }

  return store.items[index].data;
}

/**
 * Set item (mutate)
 * @param {Object} store 
 * @param {String} key 
 * @param {mixed} value 
 * @returns {Object} store
 */
export function setItem (store, key, value) {
  var index = findIndex(store, key);
  var items = [ ...store.items ];

  if (index !== -1) {
    items.splice(index, 1);
  }

  items.unshift({
    key: key,
    data: value
  });

  if (items.length > store.settings.max) {
    items.pop();
  }

  return {
    ...store,
    items: items
  }
}

/**
 * Remove item (mutate)
 * @param {Object} store 
 * @param {String} key
 * @returns {Object} store
 */
export function removeItem (store, key) {
  var index = findIndex(store, key);

  if (index === -1) {
    return store;
  }

  var items = [ ...store.items ];
  items.splice(index, 1);

  return {
    ...store,
    items: items
  }
}

export default { createStore, findIndex, hasItem, getItem, setItem, removeItem };