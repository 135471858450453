import QueueFifo from './Fifo';
import QueueWorker from './Worker';

class QueueManager {

  constructor (handler, workers = 1) {

    this.handler = handler;
    this.fifo = new QueueFifo();
    this.workersCount = workers;
    this.events = {};

    this.events.success = function () {
      // Do nothing
    };

    this.events.error = function () {
      // Do nothing
    };
  }

  onSuccess = (listener) => {
    this.events.success = listener;
    return this;
  }

  onError = (listener) => {
    this.events.error = listener;
    return this;
  }

  start = () => {
    let queueAdapter = {
      dequeue: () => {
        if (this.fifo.peek() !== undefined) {
          return this.fifo.next();
        }
        return null;
      }
    };

    this.workers = Array.from({ length: this.workersCount }, (v, k) => k).map((i) => {
      let worker = new QueueWorker(queueAdapter, this.handler);
      
      worker.onSuccess(this.events.success);
      worker.onError(this.events.error);

      return worker;
    });

    this.workers.forEach((worker) => {
      worker.start();
    });
  }

  stop = () => {
    this.workers.forEach((worker) => {
      worker.stop();
    });

    this.workers = [];
  }
}

export default QueueManager;