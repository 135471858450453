import { call, select, put } from 'redux-saga/effects';
import { Auth, Hub } from 'aws-amplify';

async function getSession () {
  try {
    var user = await Auth.currentAuthenticatedUser();

    Hub.dispatch('user', {
      event: 'token_ok',
      data: {
        id: user.attributes.sub,
        email: user.attributes.email,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        //idToken: user.signInUserSession.idToken.jwtToken,
        //refreshToken: user.signInUserSession.refreshToken.token
      }
    });

    return user;
  } catch (e) {

    Hub.dispatch('user', {
      event: 'token_error',
      data: null,
      error: e
    });

    return null;
  }
}

export default function* (action) {
  var state = yield select((state) => state);

  console.log('Initializing...', state);
  yield put({ type: 'AUTH.FETCH' });
  var user = yield call(getSession);

  var result = {
    touched: true,
    authenticated: false
  };

  if (user) {
    result.authenticated = true;
    result.user = {
      id: user.attributes.sub,
      email: user.attributes.email,
      //accessToken: user.signInUserSession.accessToken.jwtToken,
      //idToken: user.signInUserSession.idToken.jwtToken,
      //refreshToken: user.signInUserSession.refreshToken.token
    };
  }

  yield put({ type: 'AUTH.RESPONSE', payload: result });
}