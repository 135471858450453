import client from '../client';
//import httpClient from '../../shared/http-client';

const api = {};

const loadPageRecords = async function (params, context) {
  var { projectId, page, limit, parentId } = params;

  var response = await client.query({
    query: `
      query getCollections ($page: Int!, $limit: Int!, $parentId: UUID, $projectId: UUID) {
        getCollections(
          projectId: $projectId,
          page: $page,
          limit: $limit,
          parentId: $parentId,
          orderBy: [
            {
              name: "createdAt",
              reverse: false
            },
            {
              name: "title",
              reverse: false
            }
          ]
        ) {
          rowCount
          pageCount
          page
          data {
            id
            title
            totalPhotosCount: photosCount
            coverUrl
            parentCollection {
              id
              title
            }
            photos: getPhotos (limit: 1) {
              rowCount
            }
            albums: getAlbums (limit: 1) {
              rowCount
            }
            createdAt
          }
        }
      }
    `,
    variables: {
      projectId: projectId,
      parentId: parentId,
      page: page ? page : 1,
      limit: limit ? limit : 100
    }
  }, context);

  return response.getCollections;
}

/**
 * List all collection children
 * @param {object} params
 * @param {string} params.collectionId
 * @param {number} params.page
 * @param {number} params.limit
 * @param {object} context*
 * @returns {object}
 */
api.getOneCollections = async function ({ id, page, limit }, context) {
  var response = await client.query({
    query: `
      query getCollections ($parentId: UUID!, $page: Int!, $limit: Int!) {
        getCollections(
          parentId: $parentId
          page: $page,
          limit: $limit,
          orderBy: [
            {
              name: "createdAt",
              reverse: false
            },
            {
              name: "title",
              reverse: false
            }
          ]
        ) {
          rowCount
          pageCount
          page
          data {
            id
            title
            coverUrl
            parentCollection {
              id
              title
            }
            getAlbums (page: 1, limit: 1) {
              rowCount
            }
            getPhotos {
              rowCount
            }
            createdAt
          }
        }
      }
    `,
    variables: {
      parentId: id,
      page: page ? page : 1,
      limit: limit ? limit : 100
    }
  }, context);

  return response.getCollections;
}

/**
 * List all collections
 * @param {object} params
 * @param {object} context*
 */
api.getAll = async function (params, context) {  
  var response = await loadPageRecords(params, context);
  return response.data;
}

/**
 * Returns one collection
 * @param {object} params*
 * @param {string} params.id*
 * @param {object} context*
 * @returns {null|object}
 */
api.getOne = async function (params, context) {
  var response = await client.query({
    query: `
      query getCollections ($id: UUID!) {        
        getCollections(id: $id) {
          data {
            id
            title
            createdAt
            parentCollection {
              id
              title
            }
            getPhotos(page: 1, limit: 10) {
              rowCount
              page
              data {
                id
                title
                isDivider
                getTexts {
                  value
                }
                getLabels {
                  value
                }
                getMetadata {
                  key
                  value
                }
                createdAt
              }
            }
          }
        }
      }
    `,
    variables: {
      ...params
    }
  }, context);

  if (response.getCollections.data.length === 0) {
    return null;
  }

  return response.getCollections.data[0];
}

/**
 * Returns collection's photos
 * @param {object} params*
 * @param {string} params.id
 * @param {string} params.page
 * @param {string} params.limit
 * @param {object} context*
 * @returns {null|object}
 */
api.getOnePhotos = async function ({ id, page, limit, showDividers }, context) {
  var response = await client.query({
    query: `
      query getCollections ($id: UUID!, $page: Int!, $limit: Int!, $showDividers: Boolean!) {
        getCollections(id: $id) {
          data {
            getPhotos(page: $page, limit: $limit, showDividers: $showDividers) {
              rowCount
              page
              pageCount
              data {
                id
                title
                breadcrumbs {
                  id
                  title
                }                
                thumbnail: thumbnailUrl
                fullsize: optimizedUrl                
                width
                height
                thumbnailUrl
                optimizedUrl
                optimizedWidth
                optimizedHeight
                optimizedSize
                isDivider
                createdAt
              }
            }
          }
        }
      }
    `,
    variables: {
      showDividers: showDividers !== undefined ? showDividers : true,
      id,
      page,
      limit
    }
  }, context);

  if (response.getCollections.data.length === 0) {
    return null;
  }

  return response.getCollections.data[0].getPhotos;
}

/**
 * Create collection
 * @param {object} params*
 * @param {string} params.name*
 * @param {object} context*
 */
api.create = async function ({ name }, context) {
  var response = await client.mutation({
    query: `
      mutation collectionCreate ($name: String!) {
        collectionCreate (name: $name) {
          id
          title
        }
      }
    `,
    variables: {
      name
    }
  }, context);

  return response.collectionCreate;
};

/**
 * Delete collection
 * @param {object} params*
 * @param {string} params.id*
 * @param {object} context*
 */
api.delete = async function ({ id }, context) {
  var response = await client.mutation({
    query: `
      mutation collectionDelete ($id: Int!) {
        collectionDelete($id)
      }
    `,
    variables: {
      id
    }
  }, context);

  return response.collectionDelete;
};

/**
 * Rename collection
 * @param {object} params*
 * @param {string} params.id*
 * @param {string} params.name*
 * @param {object} context*
 */
api.rename = async function ({ id, name }, context) {
  // Code here
};

export default api;