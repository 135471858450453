import React from 'react';
import { connect } from 'react-redux';

import LoadingScreen from '../LoadingScreen';

const AppLoaderGateway = (props) => {
  if (!props.ready) {
    return (<LoadingScreen />);
  }

  return props.children;
}

export default connect((state) => state.app)(AppLoaderGateway);