//import { Hub } from 'aws-amplify';

class AuthClass {
  token = '';

  setToken (newToken) {
    // Code here
  }

  getToken () {
    // Code here
  }
}

export default AuthClass;