import React, { useRef, useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@teewe/components';
import { ChipGroup } from '@teewe/components';
import { Chip } from '@teewe/components';
import { FormControl } from '@teewe/components';
import { FormControlIcon } from '@teewe/components';
import styled from 'styled-components';

// Components
import DropdownList from '../DropdownList';
import DropdownListItem from '../DropdownListItem';
//import HighlightText from '../HighlightText';

export const useAutocomplete = () => {
  const [ open /*, setOpen*/ ] = React.useState(false);
  const [ value /*, setValue*/ ] = React.useState([]);
  const [ inputValue /*, setInputValue*/ ] = React.useState('');
  const [ results /*, setResults*/ ] = React.useState({ count: 0, edges: [] });

  return {
    open,
    value,
    inputValue,
    results,
    onInputValueChange: (text) => {
      /*
      if (e.target.value.length > 1) {
        getSuggestions(e.target.value)
          .then((suggestionsResponse) => {
            setSuggestions(suggestionsResponse);
          })
          .catch((e) => {
            console.error(e);
          })
      }
      */
    },
    onEsc: () => {
      /*
      setFiltersInputValue('');
      setSuggestions({ count: 0, edges: [] });
      */
    },
    onEnter: (text) => {
      // Code here
      /*
      if (suggestions.count > 0) {
        var newState = [ ...value.filters ];
        newState.push(suggestions.edges[0].id);
        setFiltersInputValue('');
        setSuggestions({ count: 0, edges: [] });
        dispatchFiltersChange(newState);
        return false;
      }
  
      dispatchSubmit();
      */
    },
    onChange: () => {
      // Code here
    }
  }
}

export const StyledAutocomplete = styled.div`
  width: 100%;
`;

const textSubstring = (text) => {
  if (text && text.length > 10) {
    return text.substring(0, 7) + '...';
  }

  return text;
}

export const AutocompleteChipItem = ({ chip, itemLabel, ...props }) => {
  return (
    <Chip color="primary" size="small" title={itemLabel(chip)} {...props}>
      <IconButton size="small" color="primary">
        <i className="fas fa-times-circle"></i>
      </IconButton>
      { textSubstring(itemLabel(chip)) }
    </Chip>
  )
}

const Autocomplete = ({ inputValue, value, expanded, results, onInputValueChange, onEnter, onEsc, onChange, onExpandedChange, itemLabel, ChipComponent, ...props }) => {
  if (!value) {
    value = [];
  }

  if (!results) {
    results = {
      count: 0,
      edges: []
    }
  }

  const inputRef = useRef(null);
  const [ width, setWidth ] = useState(null);

  useEffect(() => {
    setWidth((inputRef && inputRef.current && inputRef.current.offsetWidth) ? `${inputRef.current.offsetWidth}px` : '');
  }, [inputRef]);

  if (!itemLabel) {
    itemLabel = (value) => {
      return value.toString();
    }
  }

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleEnterPressed(e);
    }

    if (e.key === 'Escape') {
      handleEscPressed(e);
    }
  }

  const handleEnterPressed = (e) => {
    if (onEnter) {
      onEnter(e);
    }
  }

  const handleEscPressed = (e) => {
    if (onEsc) {
      onEsc(e);
    }
  }

  const handleSelectItem = (item) => {
    return (e) => {
      var updatedValue = [ ...value ];

      if (updatedValue.indexOf(item) === -1) {
        updatedValue.push(item);
      }

      if (onChange) {
        onChange(updatedValue, e);
      }
    }
  }

  const handleDeselectItem = (item) => {
    return () => {
      var index = value.indexOf(item);

      if (index === -1) {
        return false;
      }

      var updatedValue = [ ...value ];
      updatedValue.splice(index, 1);

      if (onChange) {
        onChange(updatedValue);
      }
    }
  }

  const handleInputValueChange = (e) => {
    if (onInputValueChange) {
      onInputValueChange(e.target.value);
    }
  }

  /*
  const handleExpandedChange = () => {
    if (onExpandedChange) {
      onExpandedChange(!expanded);
    }
  }
  */

  const renderSelectedItems = () => {
    if (value.length > 1) {
      return (
        <Chip color="primary" size="small">
          +{value.length}
        </Chip>
      )
    }

    return (
      <>
        {
          value.map((chip, index) => (
            <ChipComponent key={index} chip={chip} itemLabel={itemLabel} onDeselect={handleDeselectItem(chip)} />
          ))
        }
      </>
    )
  }

  const isOpen = Boolean(expanded) || Boolean(inputValue && inputValue.length > 0 && results.count > 0);

  return (
    <StyledAutocomplete {...props}>
      <FormControl ref={inputRef} color="primary" hasDropdown={isOpen} noBorder withMargin={false}>
        <FormControlIcon align="left">
          <i className="fas fa-filter"></i>
        </FormControlIcon>
        <input
          type="text"
          autoComplete="off"
          className="pui-form-control__input"
          placeholder="Filtrar coleções"
          value={inputValue}
          onChange={handleInputValueChange}
          onKeyUp={handleKeyPressed}
        />
        <ChipGroup>
          { renderSelectedItems() }
          {/*
          <Chip color="primary" size="small" onClick={handleExpandedChange}>
            <i className="fas fa-caret-down"></i>
          </Chip>
          */}
        </ChipGroup>
      </FormControl>
      <DropdownList open={isOpen} style={{width: width}}>
        <div className="pui-dropdown-list__header">
          { results.count === 0 && 'Nada encontrado' }
          { results.count === 1 && 'Uma coleção encontrada' }
          { results.count > 1 && (<span>{results.count} coleções encontradas</span>) }
          <strong> "{inputValue}"</strong>
        </div>
        <div className="pui-dropdown-list__content">
          {
            results.edges.map((edge, index) => (
              <DropdownListItem key={index} selected={false} onClick={handleSelectItem(edge.id)}>
                <span>{ itemLabel(edge) }</span>
                {/*
                <br/>
                {
                  edge.matchedTexts.slice(0, 1).map((text, highlightIndex) => (<HighlightText key={highlightIndex} element="small" value={text} />))
                }
                */}
              </DropdownListItem>
            ))
          }
        </div>
      </DropdownList>
    </StyledAutocomplete>
  )
}

Autocomplete.propTypes = {
  inputValue: PropTypes.string,
  value: PropTypes.array,
  expanded: PropTypes.bool,
};

export default Autocomplete;