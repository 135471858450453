import { Hub } from 'aws-amplify';
import httpClient from '../shared/http-client';

const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_API}/graphql/_`;

const eventHandlers = {
  'create': ({ payload }) => {
    const requestBody = {
      query: `
        mutation createLabel ($input: CreateLabelInput!) {
          createItem: createLabel(input: $input) {
            error
            data {
              id
              photoId
              value
              confidence
              isDetected
              createdAt
              updatedAt
        
            }
          }
        }
      `,
      variables: {
        input: {
          ...payload.data
        }
      }
    };

    httpClient.post(GRAPHQL_ENDPOINT, requestBody)
      .then((response) => {
        const { createItem } = response.data.data;

        var dispatchEvent = {
          previousEvent: { ...payload }
        };

        if (createItem.error) {
          dispatchEvent.event = 'create_error';
          dispatchEvent.data = {
            error: createItem.error
          }
        } else {
          dispatchEvent.event = 'create_ok';
          dispatchEvent.data = {
            ...createItem.data
          }
        }

        Hub.dispatch('photo_label', dispatchEvent);
      })
      .catch((error) => {
        var errorEventParams = {
          previousEvent: { ...payload },
          event: 'create_error',
          data: {
            error: error
          }
        };
  
        Hub.dispatch('photo_label', errorEventParams);
      });
  },
  'update': ({ payload }) => {
    const requestBody = {
      query: `
        mutation updateLabel ($input: UpdateLabelInput!) {
          updateItem: updateLabel(input: $input) {
            error
            data {
              id
              photoId
              confidence
              value
              isDetected
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        input: {
          id: payload.data.id,
          value: payload.data.value
        }
      }
    };

    httpClient.post(GRAPHQL_ENDPOINT, requestBody)
      .then((response) => {
        const { updateItem } = response.data.data;

        var dispatchEvent = {
          previousEvent: { ...payload }
        };

        if (updateItem.error) {
          dispatchEvent.event = 'update_error';
          dispatchEvent.data = {
            error: updateItem.error
          }
        } else {
          dispatchEvent.event = 'update_ok';
          dispatchEvent.data = {
            ...updateItem.data
          }
        }
  
        Hub.dispatch('photo_label', dispatchEvent);
      })
      .catch((error) => {
        var errorEventParams = {
          previousEvent: { ...payload },
          event: 'update_error',
          data: {
            error: error
          }
        };
  
        Hub.dispatch('photo_label', errorEventParams);
      });
  },
  'delete': ({ payload }) => {
    const requestBody = {
      query: `
        mutation deleteLabel ($input: DeleteLabelInput!) {
          deleteItem: deleteLabel(input: $input) {
            error
          }
        }
      `,
      variables: {
        input: {
          id: payload.data.id
        }
      }
    };

    httpClient.post(GRAPHQL_ENDPOINT, requestBody)
      .then((response) => {
        const { deleteItem } = response.data.data;

        var dispatchEvent = {
          previousEvent: { ...payload },
          event: 'delete_ok',
          data: {
            id: payload.data.id,
            photoId: payload.data.photoId
          }
        };

        if (deleteItem.error) {
          dispatchEvent.event = 'delete_error';
          dispatchEvent.data.error = deleteItem.error;
        }

        Hub.dispatch('photo_label', dispatchEvent);
      })
      .catch((error) => {
        var errorEventParams = {
          previousEvent: { ...payload },
          event: 'delete_error',
          data: {
            id: payload.data.id,
            photoId: payload.data.photoId,
            error: error
          }
        };

        Hub.dispatch('photo_label', errorEventParams);
      });
  }
};

Hub.listen('photo_label', (event) => {
  const { payload } = event;
  var eventHandler;

  if (!payload.event) {
    return false;
  }

  if (!eventHandlers[payload.event]) {
    return false;
  }

  eventHandler = eventHandlers[payload.event];
  eventHandler(event);
});

Hub.listen('photo_label', (event) => {
  console.log('photo_label', event);
});