const DEFAULT_STATE = {
  touched: false,
  loading: false,
  ready: false,
  error: null
};

export default (state = DEFAULT_STATE, action) => {
  const { type, payload } = action;

  if (type !== 'APP') {
    return state;
  }

  if (payload.type === 'START') {
    return {
      touched: true,
      loading: true,
      ready: false,
      error: null
    }
  }

  if (payload.type === 'READY') {
    return {
      touched: true,
      loading: false,
      ready: true,
      error: null
    }
  }

  if (payload.type === 'ERROR') {
    return {
      touched: true,
      loading: false,
      ready: false,
      error: payload.error || 'unexpected error'
    }
  }

  return state;
}