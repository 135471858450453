import React from 'react';
import { AppSidebarTitle } from '@teewe/components';
import { AppSidebarMenu } from '@teewe/components';
import { Divider } from '@teewe/components';

// Components
import MenuItem from '../../components/MenuItem';

const SidebarMenuDefault = ({ index, menu, section, ...props }) => {
  return (
    <React.Fragment {...props}>
      <AppSidebarTitle>{section.title}</AppSidebarTitle>
      <AppSidebarMenu>
        {
          section.items.map((option, optionIndex) => (
            <MenuItem item={option} key={optionIndex} active={index === 0 && optionIndex === 0} />
          ))
        }
      </AppSidebarMenu>
      { (menu.length > index + 1) && (<Divider />) }
    </React.Fragment>
  )
}

export default SidebarMenuDefault;