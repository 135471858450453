import { createStore, setItem, removeItem } from '../../library/remember';

const DEFAULT_STATE = createStore({
  max: 50
});

export default (state = DEFAULT_STATE, action) => {
  if (action.type !== 'PROJECT') {
    return state;
  }

  if (action.payload.type === 'SET_ITEM') {
    return setItem(state, action.payload.id, action.payload.data);
  }

  if (action.payload.type === 'REMOVE_ITEM') {
    return removeItem(state, action.payload.id);
  }

  // No action

  return state;
}