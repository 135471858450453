import axios from 'axios';
import { Promise } from 'bluebird';

import client from '../client';

const api = {};

const uploadFileS3PreSignedUrl = async function ({ uploadUrl, file, onProgress, ...params }) {
  var headers = { 'Content-Type': null };

  if (file.type) {
    headers['Content-Type'] = file.type;
  }

  if (Object.keys(params).indexOf('contentType') !== -1) {
    headers['Content-Type'] = params.contentType;
  }

  var requestOptions = {
    method: 'put',
    url: uploadUrl,
    headers: headers,
    data: file
  };

  if (onProgress) {
    requestOptions.onUploadProgress = onProgress;
  }

  var response = await axios(requestOptions);
  return response;
};

api.bulk = async function ({ collectionId, makeChildren }, context) {
  var response = await client.mutation({
    query: `
      mutation createUploadGroup ($projectId: UUID!, $collectionId: UUID!) {
        createUploadGroup (
          projectId: $projectId,
          collectionId: $collectionId
          photoCount: 15
        ) {
          id
          createdAt
        }
      }
    `,
    variables: {
      collectionId: collectionId
    }
  }, context);

  return response.createUploadGroup;
};

api.createUploadGroup = async function ({ projectId, collectionId, makeChildren, files }, context) {
  var response = await client.mutation({
    query: `
      mutation createUploadGroup ($projectId: UUID!, $collectionId: UUID!, $filesCount: Int!, $makeChildren: Boolean!) {
        createUploadGroup (
          projectId: $projectId,
          collectionId: $collectionId
          photoCount: $filesCount
          makeChildren: $makeChildren
        ) {
          id
          photoCount
          makeChildren
          createdAt
        }
      }
    `,
    variables: {
      projectId,
      collectionId,
      filesCount: files,
      makeChildren: makeChildren
    }
  }, context);

  return response.createUploadGroup;
};

api.sendGroupFiles = async function (params, context) {
  if (!params.uploadGroupId) {
    throw new Error('Parameter uploadGroupId is required');
  }

  if (!params.files) {
    throw new Error('Parameter files is required');
  }

  if (!Array.isArray(params.files)) {
    throw new Error('Parameter files must be a valid array');
  }

  if (params.files.length === 0) {
    throw new Error('Parameter files must contain at least one file');
  }

  if (!params.onStatusChange) {
    params.onStatusChange = () => {};
  }

  if (!params.onProgress) {
    params.onProgress = () => {};
  }

  const handleUpload = async (file, index) => {
    var uploadFileOptions = {
      uploadGroupId: params.uploadGroupId,
      name: file.name,
      contentType: file.type
    };

    params.onStatusChange(file, index, 'STARTING');
    var uploadFile = await api.createUploadGroupFile(uploadFileOptions, context);
    
    if (params.onCreated) {
      params.onCreated(file, index, uploadFile.id);
    }

    params.onStatusChange(file, index, 'STARTED');

    var uploadOptions = {
      uploadUrl: uploadFile.getUploadUrl,
      file: file.original,
      onProgress: (progressEvent) => {
        params.onProgress(file, index, progressEvent.loaded);
      }
    };

    await uploadFileS3PreSignedUrl(uploadOptions);
    params.onStatusChange(file, index, 'COMPLETED');
  }

  return Promise.each(params.files, function (file, index) {
    return handleUpload(file, index);
  });
};

api.createUploadGroupFile = async function ({ uploadGroupId, name, contentType }, context) {
  var response = await client.mutation({
    query: `
      mutation createUploadGroupFile ($uploadGroupId: String!, $name: String!, $contentType: String!) {
        createUploadGroupFile (
          uploadGroupId: $uploadGroupId,
          name: $name,
          contentType: $contentType
        ) {
          id
          getUploadUrl
        }
      }
    `,
    variables: {
      uploadGroupId: uploadGroupId,
      name: name,
      contentType: contentType
    }
  }, context);

  return response.createUploadGroupFile;
}

export default api;