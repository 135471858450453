import { all, takeLatest, takeEvery } from 'redux-saga/effects';

// Sagas
import initialize from './sagas/initialize';
import fetchCollection from './sagas/fetch-collection';
import fetchProjects from './sagas/fetch-projects';
//import signOut from './sagas/sign-out';
import initializeApp from './sagas/app-initialize';
import projects from './sagas/projects';

export default function* sagaRoot () {
  yield all([
    takeEvery('APP', initializeApp),
    takeLatest('INITIALIZE', initialize),
    //takeLatest('AUTH.SIGN_OUT', signOut),
    takeEvery('COLLECTIONS.FETCH', fetchCollection),
    takeEvery('ALBUMS.FETCH', fetchCollection),
    takeEvery('PROJECTS.FETCH', fetchProjects),
    takeEvery('PROJECT_SAGA', projects)
  ]);
}