import React from 'react';
//import { useEffect } from 'react';
import Logo from '../../logo.png';
import LogoMini from '../../logo-mini.png';

// Components
import { AppSidebar } from '@teewe/components';
import { AppSidebarHeader } from '@teewe/components';
import { AppSidebarBody } from '@teewe/components';
import { Divider } from '@teewe/components';

// Icons
import SidebarMenuDefault from '../../components/SidebarMenuDefault';
import SidebarMenuProject from '../../components/SidebarMenuProject';

//import useMemberAcl from '../../hooks/useMemberAcl';

const menu = [
  {
    title: 'Menu',
    component: SidebarMenuDefault,
    items: [
      {
        icon: 'fas fa-columns',
        title: 'Dashboard',
        href: '/'
      },
      {
        icon: 'far fa-images',
        title: 'Coleções',
        href: '/collections'
      },
      {
        icon: 'fas fa-users',
        title: 'Usuários',
        href: '/members'
      },
      {
        icon: 'far fa-arrow-alt-circle-up',
        title: 'Uploads',
        href: '/uploads'
      }
    ]
  },
  {
    title: 'Projetos',
    component: SidebarMenuProject
  },
  // {
  //   title: 'Sistema',
  //   component: SidebarMenuDefault,
  //   items: [
  //     {
  //       icon: 'fas fa-cog',
  //       title: 'Configurações'
  //     },
  //     {
  //       icon: 'fas fa-life-ring',
  //       title: 'Ajuda'
  //     }
  //   ]
  // }
]

function _AppSidebar ({ open, ...props }) {
  /*
  const [ data, error ] = useMemberAcl(null);

  useEffect(() => {

  }, [ projectId ]);
  */

  return (
    <AppSidebar open={open} {...props}>
      <AppSidebarHeader>
        <img src={ open ? Logo : LogoMini } alt="Logo" height="50px" />
      </AppSidebarHeader>
      <AppSidebarBody>
        {
          menu.map((section, index) => {
            return React.createElement(section.component, { key: index, menu, index, section });
          })
        }
        <Divider />
        <i className="far fa-hdd"></i>
      </AppSidebarBody>
    </AppSidebar>
  );
}

export default _AppSidebar;