import React from 'react';
import { HashRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

// Pages
import LoadingPage from './pages/Loading';

const DashboardPage = React.lazy(() => import('./pages/Dashboard'));
const AlbumsPage = React.lazy(() => import('./pages/Albums'));
const AlbumOnePage = React.lazy(() => import('./pages/AlbumOne'));
const UploadsPage = React.lazy(() => import('./pages/Uploads'));
const SearchPage = React.lazy(() => import('./pages/Search'));
const PhotoPage = React.lazy(() => import('./pages/Photo'));
const MembersPage = React.lazy(() => import('./pages/Members'));
const Error404Page = React.lazy(() => import('./pages/Error404'));
const Error5xxPage = React.lazy(() => import('./pages/Error5xx'));
const TryOutPage = React.lazy(() => import('./pages/TryOut'));

//const NotFoundPage = React.lazy(() => import('./pages/NotFound'));
//const PhotoSinglePage = React.lazy(() => import('./pages/PhotoSingle'));
//const SearchPage = React.lazy(() => import('./pages/Search'));
//const AdvancedSearchPage = React.lazy(() => import('./pages/AdvancedSearch'));

const pages = [
  {
    path: '/loading',
    component: LoadingPage,
    exact: true
  },
  {
    path: '/',
    component: DashboardPage,
    exact: true
  },
  {
    path: '/collections',
    component: AlbumsPage,
    exact: true
  },  
  {
    path: '/collections/:id',
    component: AlbumOnePage,
    exact: true
  },
  /*
  {
    path: '/collections/:id/photo/:photo',
    component: PhotoPage,
    exact: true
  },
  */
  {
    path: '/photos/:photo',
    component: PhotoPage,
    exact: true
  },
  {
    path: '/uploads',
    component: UploadsPage,
    exact: true
  },  
  {
    path: '/404',
    component: Error404Page,
    exact: false
  },
  {
    path: '/5xx',
    component: Error5xxPage,
    exact: false
  },
  {
    path: '/search',
    component: SearchPage,
    exact: false
  },
  {
    path: '/members',
    component: MembersPage,
    exact: true
  },
  {
    path: '/try',
    component: TryOutPage,
    exact: true
  }
  /*
  {
    path: '/advanced',
    component: AdvancedSearchPage,
    exact: true
  }
  */
];

function Routes() {
  return (
    <Router>
      <React.Suspense fallback={<LoadingPage />}>        
          <Switch>
            {
              pages.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={Boolean(route.exact)}
                />
              ))
            }

            <Route>
              <Error404Page />
            </Route>
          </Switch>
      </React.Suspense>
    </Router>
  );
}

export default Routes;