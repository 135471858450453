import axios from 'axios';
import { Hub } from 'aws-amplify';

import auth from '../shared/auth';

const createHttpClient = () => {
  const httpClientOptions = {
    baseURL: `${process.env.REACT_APP_API}`,
    json: true
  };

  const httpClient = axios.create(httpClientOptions);

  function configureAuthorization (token) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (auth.token && auth.token.length > 0) {
    configureAuthorization(auth.token);
  }

  Hub.listen('user', ({ payload }) => {
    if (payload.event === 'token_ok') {
      configureAuthorization(payload.data.accessToken);
    }
   });

  return httpClient;
}

export default createHttpClient;