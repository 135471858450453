import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { ButtonGroup } from '@teewe/components';
import { Button } from '@teewe/components';

import Select from '../Select';

import httpClient from '../../shared/http-client';

const StyledDialogFooter = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const styles = {
  root: {
    minWidth: '400px'
  }
};

async function fetchProjects () {
  try {
    const requestBody = {
      query: `
        query getProjects ($input: GetProjectsInput!) {
          getProjects(input: $input) {
            error
            data {
              count
              edges {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        input: {
          from: 0,
          size: 100
        }
      }
    };

    const response = await httpClient.post(`${process.env.REACT_APP_API}/graphql/_`, requestBody);
    const { getProjects } = response.data.data;
    return getProjects;
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

const parseOptions = (projects) => {
  if (!projects.touched || projects.fetching || projects.response.error) {
    return [];
  }

  if (projects.response.data) {
    return projects.response.data.edges.map((item) => {
      return {
        label: item.name,
        value: item.id
      }
    });
  }

  return [];
}

const SelectProjectDialog = ({ open, onClose, onConfirm, ...props }) => {
  const dispatch = useDispatch();
  const [ projects, setProjects ] = useState({ touched: false, fetching: false, response: null });
  const [ selected, setSelected ] = useState(null);

  useEffect(() => {
    if (open) {
      setProjects(() => {
        return {
          touched: true,
          fetching: true,
          response: {
            error: null,
            data: null
          }
        }
      });

      fetchProjects()
        .then((response) => {
          setProjects(() => {
            return {
              touched: true,
              fetching: false,
              response
            }
          });
        })
        .catch((err) => {
          setProjects((projects) => {
            return {
              ...projects,
              touched: true,
              fetching: false,
              response: {
                error: err
              }
            }
          });
        })
    }
  }, [ open ]);

  const handleSelectedChange = (selected) => {
    setSelected(selected);
  }

  const handleRefresh = () => {
    dispatch({
      type: 'PROJECTS.FETCH',
      payload: {
        force: true
      }
    });
  }

  const handleConfirm = (e) => {
    if (selected) {
      dispatch({
        type: 'PROJECTS.SELECT',
        payload: {
          id: selected
        }
      });
    }

    if (onConfirm) onConfirm(selected, e);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Selecione o projeto</DialogTitle>
      <DialogContent>
        <div style={styles.root}>
          { (!projects.touched || projects.fetching) && (<span>Carregando...</span>) }
          { (projects.touched && !projects.fetching && projects.response.error) && (<span>Erro: Falha inesperada ao carregar projetos</span>) }
          { (projects.touched && !projects.fetching && !projects.response.error) && (<Select value={selected} options={parseOptions(projects)} onChange={handleSelectedChange} onRefreshClick={handleRefresh} />) }
          {/*<span>Salvar projeto selecionado como principal?</span>*/}

          <StyledDialogFooter>
            <ButtonGroup>
              <Button variant="text" onClick={onClose}>Cancelar</Button>
              <Button variant="contained" color="primary" onClick={handleConfirm}>Selecionar</Button>
            </ButtonGroup>
          </StyledDialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}

SelectProjectDialog.propTypes = {
  open: PropTypes.bool
}

SelectProjectDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {}
}

export default SelectProjectDialog;