
/**
 * Memorize collection
 * @param {String} collectionId 
 * @param {Object} collectionData 
 * @param {Object} state
 * @returns {Object} newState
 */
const saveCollection = (collectionId, collectionData, state) => {
  var newState = { ...state };

  if (!newState.memory) {
    newState.memory = {};
  }

  newState.memory[collectionId] = { ...collectionData };
  return newState;
}

export default saveCollection;