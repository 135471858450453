import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const buildRoute = (params) => {
  var queryParams = new URLSearchParams();

  queryParams.set('q', params.q);
  queryParams.set('page', parseInt(params.page || 1));
  
  if (params.filters && Array.isArray(params.filters)) {
    queryParams.set('filters', params.filters.join(','));
  }

  return `/search?${queryParams.toString()}`;
}

function parseSearch (params) {
  const search = new URLSearchParams(params);
  const state = {
    q: search.get('q'),
    filters: [],
    page: parseInt(search.get('page') || 1)
  };

  if (search.has('filters')) {
    state.filters = search.get('filters').split(',').filter((id) => id !== '');
  }

  return state;
}

function useSearch () { 
  const location = useLocation();
  const history = useHistory();
  const [ search, _setSearch ] = useState(parseSearch(location.search));

  useEffect(() => {
    _setSearch(parseSearch(location.search));
  }, [ location.search ]);

  const setSearch = (params) => {
    history.push(buildRoute(params));
  }

  return [ search, setSearch ];
}

export default useSearch;