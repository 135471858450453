import React from 'react';
import styled from 'styled-components';
import Logo from '../../logo.png';

const StyledLoadingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #006699;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: calc(100% - 20px);
    max-width: 400px;
  }
`;

const LoadingScreen = () => {
  return (
    <StyledLoadingScreen>
      <div>
        <img src={Logo} alt="Logo IPM" className="animate__animated animate__pulse animate__infinite" />
      </div>
    </StyledLoadingScreen>
  )
}

export default LoadingScreen;