
class QueueWorker {

  constructor (queue, handler) {
    this.queue = queue;
    this.attempts = 0;

    this.run_status = 'STOPPED';
    this.queue_status = 'FREE';
    this.handler = handler;
    this.events = {
      onSuccess: function () {},
      onError: function () {}
    };
  }

  onError = (callback) => {
    this.events.onError = callback;
    return this;
  }

  onSuccess = (callback) => {
    this.events.onSuccess = callback;
    return this;
  }

  retry = () => {
    this.attempts++;

    if (this.attempts > this.maxAttempts) {
      // throw fatal error
    }

    this.sleep();
  }

  sleep = () => {
    setTimeout(() => {
      this.next();
    }, 1000);
  }

  next = () => {
    this.queue_status = 'BUSY';
    let task = this.queue.dequeue();
    
    if (!task) {
      this.queue_status = 'FREE';
      return this.sleep();
    }

    this.task = task;
    this.attempts = 0;

    this.handler(task, (err, data) => {
      if (err) {
        this.events.onError(task, err); // Notify error
        this.sleep(); // Retry or call next
      } else {
        this.events.onSuccess(task, data); // Notify success
        this.sleep(); //this.next();
      }
    });
  }

  start = () => {
    this.run_status = 'RUNNING';

    if (this.queue_status === 'FREE') {
      //this.next();
    }

    this.next();
  }
}

export default QueueWorker;