import saveCollection from '../selectors/saveCollection';
import removeCollection from '../selectors/removeCollection';

const DEFAULT_STATE = {
  memory: {}
};

const actions = {
  'COLLECTIONS.SAVE': (state, action) => {
    const { payload } = action;
    console.log(`Storing collection with ID "${payload.id}"...`);
    return saveCollection(payload.id, payload.state, state);
  },
  'COLLECTIONS.REMOVE': (state, action) => {
    const { payload } = action;
    console.log(`Removing collection with ID "${payload.id}"...`);
    return removeCollection(payload.id, state);
  }
};

export default (state = DEFAULT_STATE, action) => {
  const actionName = `${action.type}`;

  if (actions[actionName]) {
    return actions[actionName](state, action);
  }

  return state;
}