import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { TextField } from '@teewe/components';
import { FormControlIcon } from '@teewe/components';

// Components
import AutocompleteAlbums from '../../components/AutocompleteAlbums';
import useSearch, { buildRoute } from '../../components/useSearch';

export const StyledSearchRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-left: 40px;
`;

export const StyledInputRoot = styled.div`
  width: 100%;
  display: inline-flex;
  float: left;
  margin-left: 12px;
  align-items: center;
  flex-grow: 1;
`;

const SearchBarDefault = ({ projectId, ...props }) => {
  const history = useHistory();
  const [ search ] = useSearch();
  const [ querystring, setQuerystring ] = useState(search.q || '');
  const [ collections, setCollections ] = useState(search.filters || []);

  const handleQueryChange = (e) => {
    setQuerystring(e.target.value);
  }

  const handleAlbumsChange = (newAlbums) => {
    setCollections(newAlbums);

    history.push(buildRoute({
      q: querystring,
      filters: newAlbums,
      page: 1
    }));
  }

  const handleSubmit = () => {
    history.push(buildRoute({
      q: querystring,
      filters: collections,
      page: 1
    }));
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  return (
    <StyledSearchRoot>
      <StyledInputRoot>
        <TextField
          placeholder="Digite sua busca"
          color="primary"
          value={querystring}
          startIcon={(<FormControlIcon align="left"><i className="fas fa-search"></i></FormControlIcon>)}
          onChange={handleQueryChange}
          onKeyUp={handleKeyUp}
          noBorder
        />
      </StyledInputRoot>
      {/* <StyledInputRoot style={{ marginLeft: '12px'}}>
        <AutocompleteAlbums
          projectId={projectId}
          value={collections}
          onChange={handleAlbumsChange}
          onSubmit={handleSubmit}
        />
      </StyledInputRoot> */}
    </StyledSearchRoot>
  )
}

const AppBarSearchRoot = ({ projectId, auth, ...props }) => {
  const { touched, fetching, authenticated } = auth;

  if (!touched || fetching) {
    return (<span>Carregando...</span>)
  }

  if (!authenticated) {
    return (<span>Não autenticado</span>);
  }

  if (!projectId) {
    return null;
  }

  return (<SearchBarDefault projectId={projectId} />)
}

export default connect((state) => {
  return {
    auth: state.auth,
    projectId: state.projects.current
  }
})(AppBarSearchRoot);