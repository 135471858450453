import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

export const StyledSwitch = styled.label`
  width: 30px;
  height: 18px;
  display: inline-block;
  
  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  > .pui-switch__toggle {
    background-color: rgb(206,206,206);
    width: 100%;
    height: 10px;
    display: block;
    border-radius: 6px;
    cursor: pointer;
    margin-top: -18px;
  }

  > .pui-switch__toggle:after {
    content: "";
    background-color: rgb(157,157,157);
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    border-radius: 50%;
    transition: 0.4s;
    margin-top: -4px;
  }

  > input:checked + .pui-switch__toggle:after {
    transform: translateX(12px);
  }

  > input:disabled + .pui-switch__toggle {
    opacity: 0.5;
    cursor: default;
  }

  &.pui-switch--color-primary {
    > input:checked + .pui-switch__toggle {
      background-color: rgb(128,194,246);
    }

    > input:checked + .pui-switch__toggle:after {
      background-color: rgb(0,132,238);
    }
  }
`

const Switch = ({ className, color, checked, disabled, onChange, onToggle, ...props }) => {
  const handleChange = (e) => {
    if (onChange) onChange(e);

    if ((checked !== e.target.checked) && (onToggle)) onToggle(e.target.checked);
  }

  return (
    <StyledSwitch {...props} className={clsx(className, `pui-switch--color-${color}`, { 'pui-switch--checked': checked, 'pui-switch--disabled': disabled })}>
      <input type="checkbox" disabled={Boolean(disabled)} checked={Boolean(checked)} onChange={handleChange} />
      <span className="pui-switch__toggle"></span>
    </StyledSwitch>
  )
}

Switch.defaultProps = {
  disabled: false,
  checked: false,
  color: 'primary',
  onChange: () => {}
}

export default Switch;