import { call, put } from 'redux-saga/effects';
import api from '../../api';

async function fetchCollectionAsync (id) {
  try {
    var context = {
      projectId: 'alpha',
      token: '123'
    };

    var response = await api.collection.getOne({ id }, context);

    return {
      error: null,
      data: {
        id: response.id,
        title: response.title,
        parent: response.parentCollection
      }
    }
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

export default function* (action) {
  const { payload } = action;
  console.log(`Fetching collection with ID "${payload.id}"...`);

  yield put({
    type: 'COLLECTIONS.SAVE',
    payload: {
      id: payload.id,
      state: {
        fetching: true,
        error: null,
        data: null
      }
    }
  });

  var response = yield call(fetchCollectionAsync, payload.id);

  var newState = {
    fetching: false,
    error: response.error,
    data: response.data
  };

  yield put({
    type: 'COLLECTIONS.SAVE',
    payload: {
      id: payload.id,
      state: newState     
    }
  });
}