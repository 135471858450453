import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import ProjectLogo from '../../project-logo.png';

const StyledProjectImage = styled.img`
  margin-right: 20px;
`;

function AppBarProjectImage ({ projectId, ...props }) {
  if (!projectId) {
    return null;
  }

  return (<StyledProjectImage src={ProjectLogo} alt="Project logo" />)
}

export default connect((state) => {
  return {
    projectId: state.projects.current
  }
})(AppBarProjectImage);