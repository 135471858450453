import { Hub } from 'aws-amplify';
import store from '../store';
import httpClient from '../shared/http-client';

const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_API}/graphql/_`;

const createProject = async (input) => {
  const requestBody = {
    query: `
      mutation createProject ($input: CreateProjectInput!) {
        createProject (input: $input) {
          error
          data {
            id
            name
          }
        }
      }
    `,
    variables: {
      input
    }
  };

  const response = await httpClient.post(GRAPHQL_ENDPOINT, requestBody);
  const { createProject } = response.data.data;
  return createProject;
}

Hub.listen('project', ({ payload }) => {
  if (payload.event === 'create') {
    const createProjectParams = {
      name: payload.data.name
    };

    createProject(createProjectParams)
      .then((response) => {
        if (response.error) {
          Hub.dispatch('project', {
            previousEvent: { ...payload },
            event: 'create_error',
            data: {
              error: response.error
            }
          });

          return;
        }

        Hub.dispatch('project', {
          previousEvent: { ...payload },
          event: 'create_ok',
          data: {
            ...response.data
          }
        });

        if (payload.data.open) {
          const selectProjectDispatchParams = {
            type: 'PROJECTS.SELECT',
            payload: {
              id: response.data.id
            }
          };

          console.log('select', selectProjectDispatchParams);
          store.dispatch(selectProjectDispatchParams);
        }
      })
      .catch((error) => {
        Hub.dispatch('project', {
          previousEvent: { ...payload },
          event: 'create_error',
          data: {
            error: error
          }
        });
      })
  }
});

Hub.listen('project', (event) => {
  console.log('project', event);
});

Hub.listen('project', ({ payload }) => {
  if (payload.event === 'create_ok') {
    store.dispatch({
      type: 'PROJECTS.FETCH',
      payload: {
        force: true
      }
    });
  }
});