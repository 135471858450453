const DEFAULT_STATE = {
  current: (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DEFAULT_PROJECT_ID : null,
  projects: {
    touched: false,
    fetching: false,
    response: {
      error: null,
      data: null
    }
  }
};

const actions = {
  'PROJECTS.SELECT': (state, { payload }) => {
    var newState = { ...state };
    newState.current = payload.id
    return newState;
  },
  'PROJECTS.LOAD_START': (state, { payload }) => {
    var newState = { ...state };
    newState.projects.touched = true;
    newState.projects.fetching = true;
    return newState;
  },
  'PROJECTS.LOAD_FINISH': (state, { payload }) => {
    var newState = { ...state };
    newState.projects.touched = true;
    newState.projects.fetching = false;
    newState.projects.response = { ...payload };
    return newState;
  },
}

export default (state = DEFAULT_STATE, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}