import React from 'react';
import styled from 'styled-components';

const StyledMockAvatar = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #bfbfbf;
  display: inline-block;
  overflow: hidden;

  @keyframes loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }

  :before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    transform: translateX(-100%);
    background: linear-gradient(90deg, #00000000, #00000038, #00000000);
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`

const MockAvatar = (props) => {
  return (<StyledMockAvatar />);
}

export default MockAvatar;