const DEFAULT_STATE = {
  touched: false,
  fetching: false,
  authenticated: false,
  user: {
    id: null,
    email: ''
  }
};

export default (state = DEFAULT_STATE, action) => {

  if (action.type === 'AUTH.REQUEST') {
    return {
      ...state,
      fetching: true,
      authenticated: false,
      user: {
        id: null,
        email: ''
      }
    }
  }

  if (action.type === 'AUTH.RESPONSE') {
    return {
      ...state,
      touched: true,
      fetching: false,
      ...action.payload
    }
  }

  return state;
}