import * as rxjs from 'rxjs';
import { Hub } from 'aws-amplify';

import Queue from './Queue/Manager';
import api from '../../api';

class UploadManager {

  constructor (workers = 4) {
    this.queue = new Queue(this.handleUpload, workers);
    this.queue.start();
    this.queue.onSuccess(this.handleSuccess);
    this.queue.onError(this.handleError);

    // Memory queue
    this.items = [];
    this.subject = new rxjs.BehaviorSubject(this.items);
  }

  getValue () {
    return this.items;
  }

  nextValue () {
    this.subject.next([ ...this.items ]);
  }

  setGroupId = (index, uploadGroupId) => {
    this.items[index].id = uploadGroupId;
    this.nextValue();
  }

  setUploadGroupStatus = (index, status) => {
    this.items[index].status = status;
    this.nextValue();
  }

  setUploadStatus = (index, status) => {
    this.items[index].uploadStatus = status;
    this.nextValue();
  }

  setFileProgress = (uploadIndex, fileIndex, progressValue) => {
    this.items[uploadIndex].files[fileIndex].progress = progressValue;
    this.nextValue();
  }

  setFileStatus = (uploadIndex, fileIndex, statusValue) => {
    this.items[uploadIndex].files[fileIndex].status = statusValue;
    this.nextValue();
  }

  setFileId = (uploadIndex, fileIndex, uploadGroupFileId) => {
    this.items[uploadIndex].files[fileIndex].id = uploadGroupFileId;
    this.nextValue();
  }

  findById = (uploadId) => {
    var index = -1;
    
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].id === uploadId) {
        index = i;
        break;
      }
    }

    return index;
  }

  getItemAt = (index) => {
    return this.items[index];
  }

  handleUpload = (task, cb) => {
    const { data, context } = task;
    const { projectId, collectionId, makeChildren } = data;

    var createUploadGroupOptions = {
      projectId,
      collectionId,
      makeChildren,
      files: data.files.length
    };

    this.setUploadGroupStatus(data.index, 'STARTING');

    api.upload.createUploadGroup(createUploadGroupOptions, context)
      .then((uploadGroup) => {
        this.setGroupId(task.data.index, uploadGroup.id);
        this.setUploadGroupStatus(data.index, 'STARTED');
        Hub.dispatch('upload_started', { id: uploadGroup.id, task: task.data.index });

        var sendGroupFilesOptions = {
          uploadGroupId: uploadGroup.id,
          files: data.files,
          onCreated: (file, index, uploadGroupFileId) => {
            this.setFileId(data.index, index, uploadGroupFileId);
          },
          onProgress: (file, index, progress) => {
            this.setFileProgress(data.index, index, progress);
          },
          onStatusChange: (file, index, statusValue) => {
            this.setFileStatus(data.index, index, statusValue);
          }
        };

        return api.upload.sendGroupFiles(sendGroupFilesOptions, context);
      })
      .then(() => {
        this.setUploadGroupStatus(data.index, 'COMPLETED');
        this.setUploadStatus(data.index, 'COMPLETED');

        Hub.dispatch('uploads', {
          event: 'upload_completed',
          data: {
            id: this.getGroupId(data.index),
            task: data.index
          }
        });

        cb('ok');
      })
      .catch((err) => {
        Hub.dispatch('uploads', {
          event: 'upload_error',
          data: {
            id: this.getGroupId(data.index),
            task: data.index,
            err
          }
        });

        console.log('err', err);
        cb(err);
      });
  }

  handleSuccess = (task, response) => {
    console.log('Success', task, response);
    this.nextValue();
    Hub.dispatch('upload_success', { task, response });
  }

  handleError = (task, error) => {
    console.log('Error', task, error);
    this.nextValue();
    Hub.dispatch('upload_error', { task, error });
  }

  getGroupId = (index) => {
    return this.items[index].id;
  }

  addUpload = (params, context) => {
    const { projectId, collectionId, makeChildren, files, albumId, albumName  } = params;
    var item = {
      index: this.items.length,
      id: null,
      projectId,
      albumId,
      albumName,
      collectionId,
      makeChildren,
      files: files.map((file) => {
        return {
          original: file,
          id: null,
          name: file.name,
          type: file.type,
          size: file.size,
          progress: 0,
          status: 'NOT_STARTED'
        }
      }),
      status: 'NOT_STARTED',
      uploadStatus: 'PENDING',
      analyseStatus: 'PENDING'
    };

    this.items.push(item);
    this.queue.fifo.push({ data: item, context });
    this.nextValue();
    Hub.dispatch('upload_added', { item });
  }

  subscribe (observer) {
    return this.subject.subscribe(observer);
  }
}

export default UploadManager;