import { call, put, select } from 'redux-saga/effects';
import httpClient from '../../shared/http-client';

async function fetchProjects () {
  try {
    const requestBody = {
      query: `
        query getProjects ($input: GetProjectsInput!) {
          getProjects(input: $input) {
            error
            data {
              count
              edges {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        input: {
          from: 0,
          size: 100
        }
      }
    };

    const response = await httpClient.post(`${process.env.REACT_APP_API}/graphql/_`, requestBody);
    const { getProjects } = response.data.data;
    return getProjects;
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

export default function* (action) {
  const state = yield select((state) => state.projects);
  var shouldUpdate = !state.touched;

  if (action.payload && action.payload.force) {
    shouldUpdate = true;
  }

  if (!shouldUpdate) {
    return false;
  }

  yield put({
    type: 'PROJECTS.LOAD_START',
    payload: {}
  });

  var response = yield call(fetchProjects);

  yield put({
    type: 'PROJECTS.LOAD_FINISH',
    payload: response
  });
}