import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const StyledDropdownList = styled.div`
  height: 0px;
  width: 0px;
  position: relative;
  padding: 0px;
  margin: 0px;
  display: none;
  border-radius: 0px;
  box-sizing: border-box;

  &.pui-dropdown-list--open {
    min-width: 100px;
    min-height: 200px;
    width: auto;
    height: auto;
    max-width: 500px;
    /*max-height: 400px;*/
    overflow-y: hidden;
    display: block;
    background-color: #ffffff;
    border-radius: 0px 0px 22px 22px;
    position: absolute;
    z-index: 998;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.0), 0px 5px 5px rgba(0,0,0,0.15);
  }

  .pui-dropdown-list__header {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding: 7px;
  }

  .pui-dropdown-list__content {
    /*height: 300px;*/
    overflow-y: scroll;
    padding: 7px;
  }
`;

const DropdownList = ({ open, className, children, ...props }) => {
  return (<StyledDropdownList {...props} className={clsx(className, { 'pui-dropdown-list--open': open })}>{children}</StyledDropdownList>)
}

export default DropdownList;