import React from 'react';
import styled from 'styled-components';

// Components
//import { IconButton } from '@teewe/components';

// Icons
import SearchBarDefault from '../../components/SearchBarDefault';
import AppBarProject from '../AppBarProject';
import AppBarProjectImage from '../AppBarProjectImage';

const StyledAppBarSearch = styled.div`
  display: flex;
  flex-direcion: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
`;

function AppBarSearch (props) {
  return (
    <>
      <StyledAppBarSearch>
        <AppBarProjectImage />
        <AppBarProject />
        {/*
        <IconButton color="primary" size="small" withMargin>
          <i className="fas fa-ellipsis-v"></i>
        </IconButton>
        */}
      </StyledAppBarSearch>
      <SearchBarDefault />
    </>
  )
}

export default AppBarSearch;