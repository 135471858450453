import client from "../client";

const api = {};

api.getOne = async function ({ photoId }, context) {
  var response = await client.query({
    query: `
      query getPhotos ($photoId: String!) {
        getPhotos (id: $photoId) {
          rowCount
          pageCount
          data {
            id
            title
            collectionId
            collectionName
            createdAt
            thumbnailUrl
            optimizedUrl
            optimizedWidth
            optimizedHeight
            optimizedSize
            getTexts {
              id
              value
              confidence
              isAutoGenerated
              createdAt
            }
            getLabels {
              id
              value
              confidence
              isAutoGenerated
              createdAt
            }
          }
        }
      }
    `,
    variables: {
      photoId
    }
  }, context);

  if (response.getPhotos.data.length === 0) {
    return null;
  }

  return response.getPhotos.data[0];
};

api.text = api.text || {};

api.text.getAll = async function ({ photoId }, context) {
  var response = await client.query({
    query: `
      query getPhotos ($photoId: String!) {
        getPhotos (id: $photoId) {
          rowCount
          pageCount
          data {
            id
            getTexts {
              id
              value
              confidence
              isAutoGenerated
              createdAt
            }
          }
        }
      }
    `,
    variables: {
      photoId
    }
  }, context);

  if (response.getPhotos.data.length === 0) {
    return null;
  }

  return response.getPhotos.data[0].getTexts;
};

api.text.add = async function ({ photoId, text }, context) {
  var queryOptions = {
    query: `
      mutation photoAddText ($photoId: String!, $text: String!) {
        photoAddText(photoId: $photoId, value: $text) {
          id
          value
          confidence
          isAutoGenerated
          createdAt
        }
      }
    `,
    variables: {
      photoId,
      text
    }
  };
  
  var response = await client.mutation(queryOptions, context);
  return response.photoAddText;
};

api.text.remove = async function ({ id }, context) {
  var response = await client.mutation({
    query: `
      mutation photoRemoveText ($id: String!) {
        photoRemoveText(id: $id)
      }
    `,
    variables: {
      id
    }
  }, context);

  return response.photoRemoveText;
};

api.text.update = async function (params, context) {
  await api.text.remove(params, context);
  return api.text.add(params, context);
};

api.label = api.label || {};
api.label.getAll = async function ({ photoId }, context) {
  var response = await client.query({
    query: `
      query getPhotos ($photoId: String!) {
        getPhotos (id: $photoId) {
          rowCount
          pageCount
          data {
            id
            getLabels {
              id
              value
              confidence
              isAutoGenerated
              createdAt
            }
          }
        }
      }
    `,
    variables: {
      photoId
    }
  }, context);

  if (response.getPhotos.data.length === 0) {
    return null;
  }

  return response.getPhotos.data[0].getLabels;
};

api.label.add = async function ({ photoId, text }, context) {
  var response = await client.mutation({
    query: `
      mutation photoAddLabel ($photoId: String!, $text: String!) {
        photoAddLabel(photoId: $photoId, value: $text) {
          id
          value
          confidence
          isAutoGenerated
          createdAt
        }
      }
    `,
    variables: {
      photoId,
      text
    }
  }, context);
  return response.photoAddLabel;
};

api.label.remove = async function ({ id }, context) {
  var response = await client.mutation({
    query: `
      mutation photoRemoveLabel ($id: String!) {
        photoRemoveLabel(id: $id)
      }
    `,
    variables: {
      id
    }
  }, context);

  return response.photoRemoveLabel;
};

api.label.update = async function (params, context) {
  await api.label.remove(params, context);
  return api.label.add(params, context);
};

export default api;