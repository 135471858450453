
/**
 * Remove collection
 * @param {String} collectionId 
 * @param {Object} state
 * @returns {Object} newState
 */
const removeCollection = (collectionId, state) => {
  const newState = {
    ...state
  };

  if (!newState.memory) {
    newState.memory = {};
  }

  if (newState.memory[collectionId]) {
    delete(newState.memory[collectionId]);
  }

  return newState;
}

export default removeCollection;