import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

const _getValue = (value) => {
  if (!value) {
    return 'false';
  }

  return value;
}

const FormSelect = ({ value, options, loading, disabled, emptyOptionText, onChange, onRefreshClick, ...props }) => {
  var styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    select: {
      width: '100%',
      maxWidth: '350px',
      flexGrow: 2
    },
    button: {
      flexGrow: 0,
      flexShrink: 0
    }
  };

  const handleChange = (e) => {
    if (e.target.value === '') {
      e.target.value = null;
    }

    if (onChange) {
      onChange(e.target.value);
    }
  }
  
  return (
    <div style={styles.root}>
      <Select {...props} value={_getValue(value)} onChange={handleChange} disabled={loading || disabled} style={styles.select}>
        <MenuItem value='false' disabled>{emptyOptionText}</MenuItem>
        {
          options.map((item) => {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            )
          })
        }
      </Select>

      <div style={styles.button}>
        <IconButton color="primary" variant="outlined" onClick={onRefreshClick} disabled={loading || disabled}>
          <RefreshIcon />
        </IconButton>
      </div>
    </div>
  )
}

FormSelect.defaultProps = {
  value: null,
  options: [], // { value, label }
  loading: false,
  disabled: false,
  emptyOptionText: 'Selecione uma opção',
  onChange: () => {},
  onRefreshClick: () => {}
};

export default FormSelect;