import { call, select, put } from 'redux-saga/effects';
import { Auth, Hub } from 'aws-amplify';

async function getSession () {
  try {
    var user = await Auth.currentAuthenticatedUser();

    Hub.dispatch('user', {
      event: 'token_ok',
      data: {
        id: user.attributes.sub,
        email: user.attributes.email,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        //idToken: user.signInUserSession.idToken.jwtToken,
        //refreshToken: user.signInUserSession.refreshToken.token
      }
    });

    return user;
  } catch (e) {

    Hub.dispatch('user', {
      event: 'token_error',
      data: null,
      error: e
    });

    return null;
  }
}

export default function* (action) {
  const { payload } = action;
  const appState = yield select((state) => state.app);

  if (payload.type !== 'REQUEST') {
    return;
  }

  if (appState.touched) {
    return;
  }

  console.log('Initializing...');
  
  // Update app state as loading
  yield put({
    type: 'APP',
    payload: {
      type: 'START'
    }
  });

  var user = yield call(getSession);

  var result = {
    touched: true,
    authenticated: false
  };

  if (user) {
    result.authenticated = true;
    result.user = {
      id: user.attributes.sub,
      email: user.attributes.email
    };
  }

  yield put({
    type: 'AUTH.RESPONSE',
    payload: result
  });

  // Update app state to ready
  yield put({
    type: 'APP',
    payload: {
      type: 'READY'
    }
  });
}