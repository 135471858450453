import axios from 'axios';

/*
const makeQuery = function (type, name, args, body) {
  function stringifyArgs (args) {
    return args.join(', ');
  }

  return `
    ${type} ${name} (${stringifyArgs(args)}) {
      ${body}
    }
  `
};
*/

const client = {

  /**
   * Query
   * @param {object} params
   * @param {string} params.query
   * @param {object} params.variables?
   * @param {boolean} params.fullResponse?
   * @param {object} context
   * @returns {mixed}
   */
  query: async function ({ query, variables, ...params }, context) {
    if (!variables) {
      variables = {};
    }

    var requestOptions = {
      method: 'post',
      url: `${process.env.REACT_APP_API}/graphql/project/${context.projectId}`,
      data: {
        query: query,
        variables: variables
      }
    };

    if (context.token) {
      requestOptions.headers = {
        Authorization: `bearer ${context.token}`
      };
    }

    var response = await axios(requestOptions);

    if (!params.fullResponse) {
      return response.data.data;
    }

    return response;
  },

  /**
   * Mutation
   * @param {object} params
   * @param {string} params.query
   * @param {object} params.variables?
   * @param {boolean} params.fullResponse?
   * @param {object} context
   * @param {*} context 
   */
  mutation: async function ({ query, variables, ...params }, context) {
    if (!variables) {
      variables = {};
    }

    var requestOptions = {
      method: 'post',
      url: `${process.env.REACT_APP_API}/graphql/project/${context.projectId}`,
      data: {
        query: query,
        variables: variables
      }
    };

    if (context.token) {
      requestOptions.headers = {
        Authorization: `bearer ${context.token}`
      };
    }

    var response = await axios(requestOptions);

    if (!params.fullResponse) {
      return response.data.data;
    }

    return response;
  },

  /**
   * Create project client
   * @param {object} context 
   * @returns {object}
   */
  client: function (context) {
    return {
      query: function (params) {
        return client.query(params, context);
      },
      mutation: function (params) {
        return client.mutation(params, context);
      }
    }
  }
}

export default client;